{
  "homepage": {
    "header": {
      "logo-title": "Être sérieux sans se prendre au sérieux",
      "navigation": {
        "services": "Prestations",
        "about": "À propos",
        "contact": "Me contacter"
      }
    },
    "content": {
      "catchphrase": {
        "one": "CTO depuis 8 ans",
        "two": "Développeur depuis 15",
        "three": "Blagueur à toute heure"
      },
      "description": {
        "who": "Qui suis-je ?",
        "work-with-me": "Travailler avec moi"
      },
      "about": {
        "introduction": "Développeur, CTO, et désormais freelance. Je suis fullstack mais plus heureux près des serveurs.",
        "more": "En savoir plus"
      },
      "contact": {
        "title": "Travailler avec moi ?",
        "introduction": "Vous avez un projet, vous pensez que mon expérience peut vous aider ? Vous souhaitez un coup de main ponctuel, ou un accompagnement plus pérenne ? Échangeons !",
        "email": "Écrivez moi",
        "book": "Planifiez un rendez-vous"
      }
    },
    "footer": {
      "legals": "Mentions légales",
      "hosting": "Hébergement",
      "phone": "Téléphone :",
      "responsability": "Contenu édité sous la responsabilité de Frédéric Menou."
    }
  }
}
