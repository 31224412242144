// Generated by purs version 0.15.12
import * as Control_Category from "../Control.Category/index.js";
import * as Control_Semigroupoid from "../Control.Semigroupoid/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Effect_Uncurried from "../Effect.Uncurried/index.js";
import * as Record from "../Record/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var EventFn = function (x) {
    return x;
};
var unsafeEventFn = EventFn;
var semigroupoidBuilder = Control_Semigroupoid.semigroupoidFn;
var mergeNil = {
    mergeImpl: function (v) {
        return function (v1) {
            return function (v2) {
                return {};
            };
        };
    }
};
var mergeImpl = function (dict) {
    return dict.mergeImpl;
};
var mergeCons = function (dictIsSymbol) {
    var $$delete = Record["delete"](dictIsSymbol)()();
    var get = Record.get(dictIsSymbol)();
    var insert = Record.insert(dictIsSymbol)()();
    return function () {
        return function () {
            return function () {
                return function () {
                    return function (dictMerge) {
                        var mergeImpl1 = mergeImpl(dictMerge);
                        return {
                            mergeImpl: function (v) {
                                return function (fns) {
                                    return function (a) {
                                        var v1 = mergeImpl1(Type_Proxy["Proxy"].value)($$delete(Type_Proxy["Proxy"].value)(fns));
                                        var v2 = get(Type_Proxy["Proxy"].value)(fns);
                                        return insert(Type_Proxy["Proxy"].value)(v2(a))(v1(a));
                                    };
                                };
                            }
                        };
                    };
                };
            };
        };
    };
};
var merge = function () {
    return function (dictMerge) {
        return mergeImpl(dictMerge)(Type_Proxy["Proxy"].value);
    };
};
var handler_ = function ($30) {
    return Effect_Uncurried.mkEffectFn1(Data_Function["const"]($30));
};
var handler = function (v) {
    return function (cb) {
        return function ($31) {
            return cb(v($31))();
        };
    };
};
var categoryBuilder = Control_Category.categoryFn;
var syntheticEvent = /* #__PURE__ */ Control_Category.identity(categoryBuilder);
export {
    unsafeEventFn,
    handler,
    handler_,
    syntheticEvent,
    merge,
    mergeImpl,
    semigroupoidBuilder,
    categoryBuilder,
    mergeNil,
    mergeCons
};
