// Generated by purs version 0.15.12
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as React_Basic_DOM from "../React.Basic.DOM/index.js";
import * as React_Basic_DOM_Generated from "../React.Basic.DOM.Generated/index.js";
var newlines = /* #__PURE__ */ (function () {
    var $3 = Data_Array.intersperse(React_Basic_DOM_Generated.br()({}));
    var $4 = Data_Functor.map(Data_Functor.functorArray)(React_Basic_DOM.text);
    return function ($5) {
        return $3($4($5));
    };
})();
export {
    newlines
};
