// Generated by purs version 0.15.12
import * as $foreign from "./foreign.js";
var semigroupJSX = {
    append: function (a) {
        return function (b) {
            return $foreign.fragment([ a, b ]);
        };
    }
};
var provider = function (context) {
    return function (value) {
        return function (children) {
            return $foreign.element($foreign.contextProvider(context))({
                value: value,
                children: children
            });
        };
    };
};
var monoidJSX = {
    mempty: $foreign.empty,
    Semigroup0: function () {
        return semigroupJSX;
    }
};
var consumer = function (context) {
    return function (children) {
        return $foreign.element($foreign.contextConsumer(context))({
            children: children
        });
    };
};
export {
    empty,
    keyed,
    fragment,
    element,
    elementKeyed,
    createContext,
    contextProvider,
    contextConsumer
} from "./foreign.js";
export {
    provider,
    consumer,
    semigroupJSX,
    monoidJSX
};
