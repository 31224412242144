// Generated by purs version 0.15.12
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Hooks_UseTranslation from "../Hooks.UseTranslation/index.js";
import * as React_Basic from "../React.Basic/index.js";
import * as React_Basic_DOM from "../React.Basic.DOM/index.js";
import * as React_Basic_DOM_Generated from "../React.Basic.DOM.Generated/index.js";
import * as React_Basic_Hooks from "../React.Basic.Hooks/index.js";
import * as React_Basic_Hooks_Internal from "../React.Basic.Hooks.Internal/index.js";
import * as Type_Equality from "../Type.Equality/index.js";
import * as Utils from "../Utils/index.js";
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var a = /* #__PURE__ */ React_Basic_DOM_Generated.a();
var guard = /* #__PURE__ */ Data_Monoid.guard(React_Basic.monoidJSX);
var bind = /* #__PURE__ */ React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender);
var pure = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl));
var div = /* #__PURE__ */ React_Basic_DOM_Generated.div();
var legalMentions = function (v) {
    return React_Basic_DOM_Generated.section_([ React_Basic_DOM_Generated.h3_([ React_Basic_DOM.text(v.t("legals")) ]), React_Basic_DOM_Generated.p_(append(Utils.newlines([ "Funky Thunks, SASU au capital social de 1000 \u20ac.", "RCS de Lyon : 950 849 752.", "Si\xe8ge social : 119, rue de Cr\xe9qui, 69006 Lyon, France.", "Repr\xe9sent\xe9e par M. Fr\xe9d\xe9ric Menou agissant et ayant les pouvoirs n\xe9cessaires en tant que pr\xe9sident.", "Contact : " ]))([ a({
        href: "mailto:contact@funkythunks.dev",
        children: [ React_Basic_DOM.text("contact@funkthunks.dev") ]
    }) ])), guard(v.language !== "fr")(React_Basic_DOM_Generated.p_([ React_Basic_DOM.text(v.t("why-french-only")) ])) ]);
};
var hosting = function (t) {
    return React_Basic_DOM_Generated.section_([ React_Basic_DOM_Generated.h3_([ React_Basic_DOM.text(t("hosting")) ]), React_Basic_DOM_Generated.p_(Utils.newlines([ "Gandi SAS", "63, 65 Boulevard Massena, 75013 Paris, France", t("phone") + " +33170377661" ])), React_Basic_DOM_Generated.p_([ React_Basic_DOM.text(t("responsability")) ]) ]);
};
var mkComponent = /* #__PURE__ */ React_Basic_Hooks.component("Homepage.Footer")(function (v) {
    return bind(Hooks_UseTranslation.usePrefixedTranslation("homepage.footer"))(function (features) {
        return pure(React_Basic_DOM_Generated.footer_([ div({
            className: "center",
            children: [ legalMentions(features), hosting(features.t) ]
        }) ]));
    });
});
export {
    mkComponent
};
