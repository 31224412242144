// Generated by purs version 0.15.12
import * as Control_Category from "../Control.Category/index.js";
import * as Control_Semigroupoid from "../Control.Semigroupoid/index.js";
import * as Data_Nullable from "../Data.Nullable/index.js";
import * as Effect_Unsafe from "../Effect.Unsafe/index.js";
import * as React_Basic_Events from "../React.Basic.Events/index.js";
var composeFlipped = /* #__PURE__ */ Control_Semigroupoid.composeFlipped(React_Basic_Events.semigroupoidBuilder);
var identity = /* #__PURE__ */ Control_Category.identity(React_Basic_Events.categoryBuilder);
var type_ = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return e.type;
});
var timeStamp = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return e.timeStamp;
});
var targetValue = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.target.value);
});
var targetFiles = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.target.files);
});
var targetChecked = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.target.checked);
});
var target = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return e.target;
});
var stopPropagation = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Effect_Unsafe.unsafePerformEffect(function __do() {
        e.stopPropagation();
        return e;
    });
});
var shiftKey = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.shiftKey);
});
var screenY = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.screenY);
});
var screenX = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.screenX);
});
var repeat = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.repeat);
});
var relatedTarget = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.relatedTarget);
});
var preventDefault = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Effect_Unsafe.unsafePerformEffect(function __do() {
        e.preventDefault();
        return e;
    });
});
var nativeEvent = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return e.nativeEvent;
});
var metaKey = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.metaKey);
});
var location = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.location);
});
var locale = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.locale);
});
var key = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.key);
});
var isTrusted = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return e.isTrusted;
});
var isPropagationStopped = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Effect_Unsafe.unsafePerformEffect(e.isPropagationStopped);
});
var isDefaultPrevented = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Effect_Unsafe.unsafePerformEffect(e.isDefaultPrevented);
});
var eventPhaseNone = 0;
var eventPhaseCapturing = 1;
var eventPhaseBubbling = 3;
var eventPhaseAtTarget = 2;
var eventPhase = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return e.eventPhase;
});
var detail = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.detail);
});
var dataTransfer = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.dataTransfer);
});
var currentTarget = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return e.currentTarget;
});
var ctrlKey = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.ctrlKey);
});
var compositionData = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.data);
});
var code = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.code);
});
var clipboardData = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.clipboardData);
});
var clientY = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.clientY);
});
var clientX = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.clientX);
});
var $$char = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e["char"]);
});
var capture = function (eventFn) {
    return React_Basic_Events.handler(composeFlipped(preventDefault)(composeFlipped(stopPropagation)(eventFn)));
};
var capture_ = function (cb) {
    return capture(identity)(function (v) {
        return cb;
    });
};
var cancelable = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return e.cancelable;
});
var buttons = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.buttons);
});
var button = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.button);
});
var bubbles = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return e.bubbles;
});
var altKey = /* #__PURE__ */ React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.altKey);
});
export {
    capture,
    capture_,
    bubbles,
    cancelable,
    compositionData,
    eventPhase,
    eventPhaseNone,
    eventPhaseCapturing,
    eventPhaseAtTarget,
    eventPhaseBubbling,
    isTrusted,
    nativeEvent,
    preventDefault,
    isDefaultPrevented,
    stopPropagation,
    isPropagationStopped,
    target,
    currentTarget,
    relatedTarget,
    targetChecked,
    targetValue,
    targetFiles,
    timeStamp,
    type_,
    key,
    code,
    $$char as char,
    location,
    repeat,
    locale,
    ctrlKey,
    shiftKey,
    altKey,
    metaKey,
    detail,
    screenX,
    screenY,
    clientX,
    clientY,
    button,
    buttons,
    dataTransfer,
    clipboardData
};
