// Generated by purs version 0.15.12
import * as Effect_Unsafe from "../Effect.Unsafe/index.js";
import * as React_Basic from "../React.Basic/index.js";
import * as React_Basic_DOM_Internal from "../React.Basic.DOM.Internal/index.js";
var _wbr$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("wbr"));
var wbr$prime = function () {
    return _wbr$prime;
};
var wbr$prime1 = /* #__PURE__ */ wbr$prime();
var wbr = function () {
    return React_Basic.element(wbr$prime1);
};
var _video$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("video"));
var video$prime = function () {
    return _video$prime;
};
var video$prime1 = /* #__PURE__ */ video$prime();
var video = function () {
    return React_Basic.element(video$prime1);
};
var video1 = /* #__PURE__ */ video();
var video_ = function (children) {
    return video1({
        children: children
    });
};
var _var$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("var"));
var var$prime = function () {
    return _var$prime;
};
var var$prime1 = /* #__PURE__ */ var$prime();
var $$var = function () {
    return React_Basic.element(var$prime1);
};
var var1 = /* #__PURE__ */ $$var();
var var_ = function (children) {
    return var1({
        children: children
    });
};
var _ul$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("ul"));
var ul$prime = function () {
    return _ul$prime;
};
var ul$prime1 = /* #__PURE__ */ ul$prime();
var ul = function () {
    return React_Basic.element(ul$prime1);
};
var ul1 = /* #__PURE__ */ ul();
var ul_ = function (children) {
    return ul1({
        children: children
    });
};
var _u$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("u"));
var u$prime = function () {
    return _u$prime;
};
var u$prime1 = /* #__PURE__ */ u$prime();
var u = function () {
    return React_Basic.element(u$prime1);
};
var u1 = /* #__PURE__ */ u();
var u_ = function (children) {
    return u1({
        children: children
    });
};
var _track$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("track"));
var track$prime = function () {
    return _track$prime;
};
var track$prime1 = /* #__PURE__ */ track$prime();
var track = function () {
    return React_Basic.element(track$prime1);
};
var _tr$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("tr"));
var tr$prime = function () {
    return _tr$prime;
};
var tr$prime1 = /* #__PURE__ */ tr$prime();
var tr = function () {
    return React_Basic.element(tr$prime1);
};
var tr1 = /* #__PURE__ */ tr();
var tr_ = function (children) {
    return tr1({
        children: children
    });
};
var _title$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("title"));
var title$prime = function () {
    return _title$prime;
};
var title$prime1 = /* #__PURE__ */ title$prime();
var title = function () {
    return React_Basic.element(title$prime1);
};
var title1 = /* #__PURE__ */ title();
var title_ = function (children) {
    return title1({
        children: children
    });
};
var _time$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("time"));
var time$prime = function () {
    return _time$prime;
};
var time$prime1 = /* #__PURE__ */ time$prime();
var time = function () {
    return React_Basic.element(time$prime1);
};
var time1 = /* #__PURE__ */ time();
var time_ = function (children) {
    return time1({
        children: children
    });
};
var _thead$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("thead"));
var thead$prime = function () {
    return _thead$prime;
};
var thead$prime1 = /* #__PURE__ */ thead$prime();
var thead = function () {
    return React_Basic.element(thead$prime1);
};
var thead1 = /* #__PURE__ */ thead();
var thead_ = function (children) {
    return thead1({
        children: children
    });
};
var _th$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("th"));
var th$prime = function () {
    return _th$prime;
};
var th$prime1 = /* #__PURE__ */ th$prime();
var th = function () {
    return React_Basic.element(th$prime1);
};
var th1 = /* #__PURE__ */ th();
var th_ = function (children) {
    return th1({
        children: children
    });
};
var _tfoot$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("tfoot"));
var tfoot$prime = function () {
    return _tfoot$prime;
};
var tfoot$prime1 = /* #__PURE__ */ tfoot$prime();
var tfoot = function () {
    return React_Basic.element(tfoot$prime1);
};
var tfoot1 = /* #__PURE__ */ tfoot();
var tfoot_ = function (children) {
    return tfoot1({
        children: children
    });
};
var _textarea$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("textarea"));
var textarea$prime = function () {
    return _textarea$prime;
};
var textarea$prime1 = /* #__PURE__ */ textarea$prime();
var textarea = function () {
    return React_Basic.element(textarea$prime1);
};
var textarea1 = /* #__PURE__ */ textarea();
var textarea_ = function (children) {
    return textarea1({
        children: children
    });
};
var _template$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("template"));
var template$prime = function () {
    return _template$prime;
};
var template$prime1 = /* #__PURE__ */ template$prime();
var template = function () {
    return React_Basic.element(template$prime1);
};
var template1 = /* #__PURE__ */ template();
var template_ = function (children) {
    return template1({
        children: children
    });
};
var _td$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("td"));
var td$prime = function () {
    return _td$prime;
};
var td$prime1 = /* #__PURE__ */ td$prime();
var td = function () {
    return React_Basic.element(td$prime1);
};
var td1 = /* #__PURE__ */ td();
var td_ = function (children) {
    return td1({
        children: children
    });
};
var _tbody$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("tbody"));
var tbody$prime = function () {
    return _tbody$prime;
};
var tbody$prime1 = /* #__PURE__ */ tbody$prime();
var tbody = function () {
    return React_Basic.element(tbody$prime1);
};
var tbody1 = /* #__PURE__ */ tbody();
var tbody_ = function (children) {
    return tbody1({
        children: children
    });
};
var _table$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("table"));
var table$prime = function () {
    return _table$prime;
};
var table$prime1 = /* #__PURE__ */ table$prime();
var table = function () {
    return React_Basic.element(table$prime1);
};
var table1 = /* #__PURE__ */ table();
var table_ = function (children) {
    return table1({
        children: children
    });
};
var _sup$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("sup"));
var sup$prime = function () {
    return _sup$prime;
};
var sup$prime1 = /* #__PURE__ */ sup$prime();
var sup = function () {
    return React_Basic.element(sup$prime1);
};
var sup1 = /* #__PURE__ */ sup();
var sup_ = function (children) {
    return sup1({
        children: children
    });
};
var _summary$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("summary"));
var summary$prime = function () {
    return _summary$prime;
};
var summary$prime1 = /* #__PURE__ */ summary$prime();
var summary = function () {
    return React_Basic.element(summary$prime1);
};
var summary1 = /* #__PURE__ */ summary();
var summary_ = function (children) {
    return summary1({
        children: children
    });
};
var _sub$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("sub"));
var sub$prime = function () {
    return _sub$prime;
};
var sub$prime1 = /* #__PURE__ */ sub$prime();
var sub = function () {
    return React_Basic.element(sub$prime1);
};
var sub1 = /* #__PURE__ */ sub();
var sub_ = function (children) {
    return sub1({
        children: children
    });
};
var _style$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("style"));
var style$prime = function () {
    return _style$prime;
};
var style$prime1 = /* #__PURE__ */ style$prime();
var style = function () {
    return React_Basic.element(style$prime1);
};
var style1 = /* #__PURE__ */ style();
var style_ = function (children) {
    return style1({
        children: children
    });
};
var _strong$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("strong"));
var strong$prime = function () {
    return _strong$prime;
};
var strong$prime1 = /* #__PURE__ */ strong$prime();
var strong = function () {
    return React_Basic.element(strong$prime1);
};
var strong1 = /* #__PURE__ */ strong();
var strong_ = function (children) {
    return strong1({
        children: children
    });
};
var _span$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("span"));
var span$prime = function () {
    return _span$prime;
};
var span$prime1 = /* #__PURE__ */ span$prime();
var span = function () {
    return React_Basic.element(span$prime1);
};
var span1 = /* #__PURE__ */ span();
var span_ = function (children) {
    return span1({
        children: children
    });
};
var _source$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("source"));
var source$prime = function () {
    return _source$prime;
};
var source$prime1 = /* #__PURE__ */ source$prime();
var source = function () {
    return React_Basic.element(source$prime1);
};
var _small$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("small"));
var small$prime = function () {
    return _small$prime;
};
var small$prime1 = /* #__PURE__ */ small$prime();
var small = function () {
    return React_Basic.element(small$prime1);
};
var small1 = /* #__PURE__ */ small();
var small_ = function (children) {
    return small1({
        children: children
    });
};
var _slot$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("slot"));
var slot$prime = function () {
    return _slot$prime;
};
var slot$prime1 = /* #__PURE__ */ slot$prime();
var slot = function () {
    return React_Basic.element(slot$prime1);
};
var slot1 = /* #__PURE__ */ slot();
var slot_ = function (children) {
    return slot1({
        children: children
    });
};
var _select$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("select"));
var select$prime = function () {
    return _select$prime;
};
var select$prime1 = /* #__PURE__ */ select$prime();
var select = function () {
    return React_Basic.element(select$prime1);
};
var select1 = /* #__PURE__ */ select();
var select_ = function (children) {
    return select1({
        children: children
    });
};
var _section$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("section"));
var section$prime = function () {
    return _section$prime;
};
var section$prime1 = /* #__PURE__ */ section$prime();
var section = function () {
    return React_Basic.element(section$prime1);
};
var section1 = /* #__PURE__ */ section();
var section_ = function (children) {
    return section1({
        children: children
    });
};
var _script$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("script"));
var script$prime = function () {
    return _script$prime;
};
var script$prime1 = /* #__PURE__ */ script$prime();
var script = function () {
    return React_Basic.element(script$prime1);
};
var script1 = /* #__PURE__ */ script();
var script_ = function (children) {
    return script1({
        children: children
    });
};
var _samp$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("samp"));
var samp$prime = function () {
    return _samp$prime;
};
var samp$prime1 = /* #__PURE__ */ samp$prime();
var samp = function () {
    return React_Basic.element(samp$prime1);
};
var samp1 = /* #__PURE__ */ samp();
var samp_ = function (children) {
    return samp1({
        children: children
    });
};
var _s$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("s"));
var s$prime = function () {
    return _s$prime;
};
var s$prime1 = /* #__PURE__ */ s$prime();
var s = function () {
    return React_Basic.element(s$prime1);
};
var s1 = /* #__PURE__ */ s();
var s_ = function (children) {
    return s1({
        children: children
    });
};
var _ruby$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("ruby"));
var ruby$prime = function () {
    return _ruby$prime;
};
var ruby$prime1 = /* #__PURE__ */ ruby$prime();
var ruby = function () {
    return React_Basic.element(ruby$prime1);
};
var ruby1 = /* #__PURE__ */ ruby();
var ruby_ = function (children) {
    return ruby1({
        children: children
    });
};
var _rtc$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("rtc"));
var rtc$prime = function () {
    return _rtc$prime;
};
var rtc$prime1 = /* #__PURE__ */ rtc$prime();
var rtc = function () {
    return React_Basic.element(rtc$prime1);
};
var rtc1 = /* #__PURE__ */ rtc();
var rtc_ = function (children) {
    return rtc1({
        children: children
    });
};
var _rt$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("rt"));
var rt$prime = function () {
    return _rt$prime;
};
var rt$prime1 = /* #__PURE__ */ rt$prime();
var rt = function () {
    return React_Basic.element(rt$prime1);
};
var rt1 = /* #__PURE__ */ rt();
var rt_ = function (children) {
    return rt1({
        children: children
    });
};
var _rp$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("rp"));
var rp$prime = function () {
    return _rp$prime;
};
var rp$prime1 = /* #__PURE__ */ rp$prime();
var rp = function () {
    return React_Basic.element(rp$prime1);
};
var rp1 = /* #__PURE__ */ rp();
var rp_ = function (children) {
    return rp1({
        children: children
    });
};
var _rb$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("rb"));
var rb$prime = function () {
    return _rb$prime;
};
var rb$prime1 = /* #__PURE__ */ rb$prime();
var rb = function () {
    return React_Basic.element(rb$prime1);
};
var rb1 = /* #__PURE__ */ rb();
var rb_ = function (children) {
    return rb1({
        children: children
    });
};
var _q$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("q"));
var q$prime = function () {
    return _q$prime;
};
var q$prime1 = /* #__PURE__ */ q$prime();
var q = function () {
    return React_Basic.element(q$prime1);
};
var q1 = /* #__PURE__ */ q();
var q_ = function (children) {
    return q1({
        children: children
    });
};
var _progress$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("progress"));
var progress$prime = function () {
    return _progress$prime;
};
var progress$prime1 = /* #__PURE__ */ progress$prime();
var progress = function () {
    return React_Basic.element(progress$prime1);
};
var progress1 = /* #__PURE__ */ progress();
var progress_ = function (children) {
    return progress1({
        children: children
    });
};
var _pre$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("pre"));
var pre$prime = function () {
    return _pre$prime;
};
var pre$prime1 = /* #__PURE__ */ pre$prime();
var pre = function () {
    return React_Basic.element(pre$prime1);
};
var pre1 = /* #__PURE__ */ pre();
var pre_ = function (children) {
    return pre1({
        children: children
    });
};
var _picture$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("picture"));
var picture$prime = function () {
    return _picture$prime;
};
var picture$prime1 = /* #__PURE__ */ picture$prime();
var picture = function () {
    return React_Basic.element(picture$prime1);
};
var picture1 = /* #__PURE__ */ picture();
var picture_ = function (children) {
    return picture1({
        children: children
    });
};
var _param$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("param"));
var param$prime = function () {
    return _param$prime;
};
var param$prime1 = /* #__PURE__ */ param$prime();
var param = function () {
    return React_Basic.element(param$prime1);
};
var _p$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("p"));
var p$prime = function () {
    return _p$prime;
};
var p$prime1 = /* #__PURE__ */ p$prime();
var p = function () {
    return React_Basic.element(p$prime1);
};
var p1 = /* #__PURE__ */ p();
var p_ = function (children) {
    return p1({
        children: children
    });
};
var _output$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("output"));
var output$prime = function () {
    return _output$prime;
};
var output$prime1 = /* #__PURE__ */ output$prime();
var output = function () {
    return React_Basic.element(output$prime1);
};
var output1 = /* #__PURE__ */ output();
var output_ = function (children) {
    return output1({
        children: children
    });
};
var _option$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("option"));
var option$prime = function () {
    return _option$prime;
};
var option$prime1 = /* #__PURE__ */ option$prime();
var option = function () {
    return React_Basic.element(option$prime1);
};
var option1 = /* #__PURE__ */ option();
var option_ = function (children) {
    return option1({
        children: children
    });
};
var _optgroup$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("optgroup"));
var optgroup$prime = function () {
    return _optgroup$prime;
};
var optgroup$prime1 = /* #__PURE__ */ optgroup$prime();
var optgroup = function () {
    return React_Basic.element(optgroup$prime1);
};
var optgroup1 = /* #__PURE__ */ optgroup();
var optgroup_ = function (children) {
    return optgroup1({
        children: children
    });
};
var _ol$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("ol"));
var ol$prime = function () {
    return _ol$prime;
};
var ol$prime1 = /* #__PURE__ */ ol$prime();
var ol = function () {
    return React_Basic.element(ol$prime1);
};
var ol1 = /* #__PURE__ */ ol();
var ol_ = function (children) {
    return ol1({
        children: children
    });
};
var _object$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("object"));
var object$prime = function () {
    return _object$prime;
};
var object$prime1 = /* #__PURE__ */ object$prime();
var object = function () {
    return React_Basic.element(object$prime1);
};
var object1 = /* #__PURE__ */ object();
var object_ = function (children) {
    return object1({
        children: children
    });
};
var _noscript$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("noscript"));
var noscript$prime = function () {
    return _noscript$prime;
};
var noscript$prime1 = /* #__PURE__ */ noscript$prime();
var noscript = function () {
    return React_Basic.element(noscript$prime1);
};
var noscript1 = /* #__PURE__ */ noscript();
var noscript_ = function (children) {
    return noscript1({
        children: children
    });
};
var _nav$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("nav"));
var nav$prime = function () {
    return _nav$prime;
};
var nav$prime1 = /* #__PURE__ */ nav$prime();
var nav = function () {
    return React_Basic.element(nav$prime1);
};
var nav1 = /* #__PURE__ */ nav();
var nav_ = function (children) {
    return nav1({
        children: children
    });
};
var _meter$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("meter"));
var meter$prime = function () {
    return _meter$prime;
};
var meter$prime1 = /* #__PURE__ */ meter$prime();
var meter = function () {
    return React_Basic.element(meter$prime1);
};
var meter1 = /* #__PURE__ */ meter();
var meter_ = function (children) {
    return meter1({
        children: children
    });
};
var _meta$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("meta"));
var meta$prime = function () {
    return _meta$prime;
};
var meta$prime1 = /* #__PURE__ */ meta$prime();
var meta = function () {
    return React_Basic.element(meta$prime1);
};
var _menuitem$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("menuitem"));
var menuitem$prime = function () {
    return _menuitem$prime;
};
var menuitem$prime1 = /* #__PURE__ */ menuitem$prime();
var menuitem = function () {
    return React_Basic.element(menuitem$prime1);
};
var menuitem1 = /* #__PURE__ */ menuitem();
var menuitem_ = function (children) {
    return menuitem1({
        children: children
    });
};
var _menu$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("menu"));
var menu$prime = function () {
    return _menu$prime;
};
var menu$prime1 = /* #__PURE__ */ menu$prime();
var menu = function () {
    return React_Basic.element(menu$prime1);
};
var menu1 = /* #__PURE__ */ menu();
var menu_ = function (children) {
    return menu1({
        children: children
    });
};
var _math$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("math"));
var math$prime = function () {
    return _math$prime;
};
var math$prime1 = /* #__PURE__ */ math$prime();
var math = function () {
    return React_Basic.element(math$prime1);
};
var math1 = /* #__PURE__ */ math();
var math_ = function (children) {
    return math1({
        children: children
    });
};
var _mark$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("mark"));
var mark$prime = function () {
    return _mark$prime;
};
var mark$prime1 = /* #__PURE__ */ mark$prime();
var mark = function () {
    return React_Basic.element(mark$prime1);
};
var mark1 = /* #__PURE__ */ mark();
var mark_ = function (children) {
    return mark1({
        children: children
    });
};
var _map$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("map"));
var map$prime = function () {
    return _map$prime;
};
var map$prime1 = /* #__PURE__ */ map$prime();
var map = function () {
    return React_Basic.element(map$prime1);
};
var map1 = /* #__PURE__ */ map();
var map_ = function (children) {
    return map1({
        children: children
    });
};
var _main$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("main"));
var main$prime = function () {
    return _main$prime;
};
var main$prime1 = /* #__PURE__ */ main$prime();
var main = function () {
    return React_Basic.element(main$prime1);
};
var main1 = /* #__PURE__ */ main();
var main_ = function (children) {
    return main1({
        children: children
    });
};
var _link$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("link"));
var link$prime = function () {
    return _link$prime;
};
var link$prime1 = /* #__PURE__ */ link$prime();
var link = function () {
    return React_Basic.element(link$prime1);
};
var _li$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("li"));
var li$prime = function () {
    return _li$prime;
};
var li$prime1 = /* #__PURE__ */ li$prime();
var li = function () {
    return React_Basic.element(li$prime1);
};
var li1 = /* #__PURE__ */ li();
var li_ = function (children) {
    return li1({
        children: children
    });
};
var _legend$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("legend"));
var legend$prime = function () {
    return _legend$prime;
};
var legend$prime1 = /* #__PURE__ */ legend$prime();
var legend = function () {
    return React_Basic.element(legend$prime1);
};
var legend1 = /* #__PURE__ */ legend();
var legend_ = function (children) {
    return legend1({
        children: children
    });
};
var _label$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("label"));
var label$prime = function () {
    return _label$prime;
};
var label$prime1 = /* #__PURE__ */ label$prime();
var label = function () {
    return React_Basic.element(label$prime1);
};
var label1 = /* #__PURE__ */ label();
var label_ = function (children) {
    return label1({
        children: children
    });
};
var _keygen$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("keygen"));
var keygen$prime = function () {
    return _keygen$prime;
};
var keygen$prime1 = /* #__PURE__ */ keygen$prime();
var keygen = function () {
    return React_Basic.element(keygen$prime1);
};
var keygen1 = /* #__PURE__ */ keygen();
var keygen_ = function (children) {
    return keygen1({
        children: children
    });
};
var _kbd$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("kbd"));
var kbd$prime = function () {
    return _kbd$prime;
};
var kbd$prime1 = /* #__PURE__ */ kbd$prime();
var kbd = function () {
    return React_Basic.element(kbd$prime1);
};
var kbd1 = /* #__PURE__ */ kbd();
var kbd_ = function (children) {
    return kbd1({
        children: children
    });
};
var _ins$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("ins"));
var ins$prime = function () {
    return _ins$prime;
};
var ins$prime1 = /* #__PURE__ */ ins$prime();
var ins = function () {
    return React_Basic.element(ins$prime1);
};
var ins1 = /* #__PURE__ */ ins();
var ins_ = function (children) {
    return ins1({
        children: children
    });
};
var _input$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("input"));
var input$prime = function () {
    return _input$prime;
};
var input$prime1 = /* #__PURE__ */ input$prime();
var input = function () {
    return React_Basic.element(input$prime1);
};
var _img$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("img"));
var img$prime = function () {
    return _img$prime;
};
var img$prime1 = /* #__PURE__ */ img$prime();
var img = function () {
    return React_Basic.element(img$prime1);
};
var _iframe$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("iframe"));
var iframe$prime = function () {
    return _iframe$prime;
};
var iframe$prime1 = /* #__PURE__ */ iframe$prime();
var iframe = function () {
    return React_Basic.element(iframe$prime1);
};
var iframe1 = /* #__PURE__ */ iframe();
var iframe_ = function (children) {
    return iframe1({
        children: children
    });
};
var _i$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("i"));
var i$prime = function () {
    return _i$prime;
};
var i$prime1 = /* #__PURE__ */ i$prime();
var i = function () {
    return React_Basic.element(i$prime1);
};
var i1 = /* #__PURE__ */ i();
var i_ = function (children) {
    return i1({
        children: children
    });
};
var _html$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("html"));
var html$prime = function () {
    return _html$prime;
};
var html$prime1 = /* #__PURE__ */ html$prime();
var html = function () {
    return React_Basic.element(html$prime1);
};
var html1 = /* #__PURE__ */ html();
var html_ = function (children) {
    return html1({
        children: children
    });
};
var _hr$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("hr"));
var hr$prime = function () {
    return _hr$prime;
};
var hr$prime1 = /* #__PURE__ */ hr$prime();
var hr = function () {
    return React_Basic.element(hr$prime1);
};
var _hgroup$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("hgroup"));
var hgroup$prime = function () {
    return _hgroup$prime;
};
var hgroup$prime1 = /* #__PURE__ */ hgroup$prime();
var hgroup = function () {
    return React_Basic.element(hgroup$prime1);
};
var hgroup1 = /* #__PURE__ */ hgroup();
var hgroup_ = function (children) {
    return hgroup1({
        children: children
    });
};
var _header$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("header"));
var header$prime = function () {
    return _header$prime;
};
var header$prime1 = /* #__PURE__ */ header$prime();
var header = function () {
    return React_Basic.element(header$prime1);
};
var header1 = /* #__PURE__ */ header();
var header_ = function (children) {
    return header1({
        children: children
    });
};
var _head$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("head"));
var head$prime = function () {
    return _head$prime;
};
var head$prime1 = /* #__PURE__ */ head$prime();
var head = function () {
    return React_Basic.element(head$prime1);
};
var head1 = /* #__PURE__ */ head();
var head_ = function (children) {
    return head1({
        children: children
    });
};
var _h6$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("h6"));
var h6$prime = function () {
    return _h6$prime;
};
var h6$prime1 = /* #__PURE__ */ h6$prime();
var h6 = function () {
    return React_Basic.element(h6$prime1);
};
var h61 = /* #__PURE__ */ h6();
var h6_ = function (children) {
    return h61({
        children: children
    });
};
var _h5$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("h5"));
var h5$prime = function () {
    return _h5$prime;
};
var h5$prime1 = /* #__PURE__ */ h5$prime();
var h5 = function () {
    return React_Basic.element(h5$prime1);
};
var h51 = /* #__PURE__ */ h5();
var h5_ = function (children) {
    return h51({
        children: children
    });
};
var _h4$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("h4"));
var h4$prime = function () {
    return _h4$prime;
};
var h4$prime1 = /* #__PURE__ */ h4$prime();
var h4 = function () {
    return React_Basic.element(h4$prime1);
};
var h41 = /* #__PURE__ */ h4();
var h4_ = function (children) {
    return h41({
        children: children
    });
};
var _h3$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("h3"));
var h3$prime = function () {
    return _h3$prime;
};
var h3$prime1 = /* #__PURE__ */ h3$prime();
var h3 = function () {
    return React_Basic.element(h3$prime1);
};
var h31 = /* #__PURE__ */ h3();
var h3_ = function (children) {
    return h31({
        children: children
    });
};
var _h2$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("h2"));
var h2$prime = function () {
    return _h2$prime;
};
var h2$prime1 = /* #__PURE__ */ h2$prime();
var h2 = function () {
    return React_Basic.element(h2$prime1);
};
var h21 = /* #__PURE__ */ h2();
var h2_ = function (children) {
    return h21({
        children: children
    });
};
var _h1$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("h1"));
var h1$prime = function () {
    return _h1$prime;
};
var h1$prime1 = /* #__PURE__ */ h1$prime();
var h1 = function () {
    return React_Basic.element(h1$prime1);
};
var h11 = /* #__PURE__ */ h1();
var h1_ = function (children) {
    return h11({
        children: children
    });
};
var _form$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("form"));
var form$prime = function () {
    return _form$prime;
};
var form$prime1 = /* #__PURE__ */ form$prime();
var form = function () {
    return React_Basic.element(form$prime1);
};
var form1 = /* #__PURE__ */ form();
var form_ = function (children) {
    return form1({
        children: children
    });
};
var _footer$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("footer"));
var footer$prime = function () {
    return _footer$prime;
};
var footer$prime1 = /* #__PURE__ */ footer$prime();
var footer = function () {
    return React_Basic.element(footer$prime1);
};
var footer1 = /* #__PURE__ */ footer();
var footer_ = function (children) {
    return footer1({
        children: children
    });
};
var _figure$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("figure"));
var figure$prime = function () {
    return _figure$prime;
};
var figure$prime1 = /* #__PURE__ */ figure$prime();
var figure = function () {
    return React_Basic.element(figure$prime1);
};
var figure1 = /* #__PURE__ */ figure();
var figure_ = function (children) {
    return figure1({
        children: children
    });
};
var _figcaption$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("figcaption"));
var figcaption$prime = function () {
    return _figcaption$prime;
};
var figcaption$prime1 = /* #__PURE__ */ figcaption$prime();
var figcaption = function () {
    return React_Basic.element(figcaption$prime1);
};
var figcaption1 = /* #__PURE__ */ figcaption();
var figcaption_ = function (children) {
    return figcaption1({
        children: children
    });
};
var _fieldset$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("fieldset"));
var fieldset$prime = function () {
    return _fieldset$prime;
};
var fieldset$prime1 = /* #__PURE__ */ fieldset$prime();
var fieldset = function () {
    return React_Basic.element(fieldset$prime1);
};
var fieldset1 = /* #__PURE__ */ fieldset();
var fieldset_ = function (children) {
    return fieldset1({
        children: children
    });
};
var _embed$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("embed"));
var embed$prime = function () {
    return _embed$prime;
};
var embed$prime1 = /* #__PURE__ */ embed$prime();
var embed = function () {
    return React_Basic.element(embed$prime1);
};
var _em$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("em"));
var em$prime = function () {
    return _em$prime;
};
var em$prime1 = /* #__PURE__ */ em$prime();
var em = function () {
    return React_Basic.element(em$prime1);
};
var em1 = /* #__PURE__ */ em();
var em_ = function (children) {
    return em1({
        children: children
    });
};
var _dt$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("dt"));
var dt$prime = function () {
    return _dt$prime;
};
var dt$prime1 = /* #__PURE__ */ dt$prime();
var dt = function () {
    return React_Basic.element(dt$prime1);
};
var dt1 = /* #__PURE__ */ dt();
var dt_ = function (children) {
    return dt1({
        children: children
    });
};
var _dl$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("dl"));
var dl$prime = function () {
    return _dl$prime;
};
var dl$prime1 = /* #__PURE__ */ dl$prime();
var dl = function () {
    return React_Basic.element(dl$prime1);
};
var dl1 = /* #__PURE__ */ dl();
var dl_ = function (children) {
    return dl1({
        children: children
    });
};
var _div$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("div"));
var div$prime = function () {
    return _div$prime;
};
var div$prime1 = /* #__PURE__ */ div$prime();
var div = function () {
    return React_Basic.element(div$prime1);
};
var div1 = /* #__PURE__ */ div();
var div_ = function (children) {
    return div1({
        children: children
    });
};
var _dialog$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("dialog"));
var dialog$prime = function () {
    return _dialog$prime;
};
var dialog$prime1 = /* #__PURE__ */ dialog$prime();
var dialog = function () {
    return React_Basic.element(dialog$prime1);
};
var dialog1 = /* #__PURE__ */ dialog();
var dialog_ = function (children) {
    return dialog1({
        children: children
    });
};
var _dfn$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("dfn"));
var dfn$prime = function () {
    return _dfn$prime;
};
var dfn$prime1 = /* #__PURE__ */ dfn$prime();
var dfn = function () {
    return React_Basic.element(dfn$prime1);
};
var dfn1 = /* #__PURE__ */ dfn();
var dfn_ = function (children) {
    return dfn1({
        children: children
    });
};
var _details$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("details"));
var details$prime = function () {
    return _details$prime;
};
var details$prime1 = /* #__PURE__ */ details$prime();
var details = function () {
    return React_Basic.element(details$prime1);
};
var details1 = /* #__PURE__ */ details();
var details_ = function (children) {
    return details1({
        children: children
    });
};
var _del$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("del"));
var del$prime = function () {
    return _del$prime;
};
var del$prime1 = /* #__PURE__ */ del$prime();
var del = function () {
    return React_Basic.element(del$prime1);
};
var del1 = /* #__PURE__ */ del();
var del_ = function (children) {
    return del1({
        children: children
    });
};
var _dd$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("dd"));
var dd$prime = function () {
    return _dd$prime;
};
var dd$prime1 = /* #__PURE__ */ dd$prime();
var dd = function () {
    return React_Basic.element(dd$prime1);
};
var dd1 = /* #__PURE__ */ dd();
var dd_ = function (children) {
    return dd1({
        children: children
    });
};
var _datalist$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("datalist"));
var datalist$prime = function () {
    return _datalist$prime;
};
var datalist$prime1 = /* #__PURE__ */ datalist$prime();
var datalist = function () {
    return React_Basic.element(datalist$prime1);
};
var datalist1 = /* #__PURE__ */ datalist();
var datalist_ = function (children) {
    return datalist1({
        children: children
    });
};
var _data$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("data"));
var data$prime$prime = function () {
    return _data$prime;
};
var data$prime$prime1 = /* #__PURE__ */ data$prime$prime();
var data$prime = function () {
    return React_Basic.element(data$prime$prime1);
};
var data$prime1 = /* #__PURE__ */ data$prime();
var data_ = function (children) {
    return data$prime1({
        children: children
    });
};
var _colgroup$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("colgroup"));
var colgroup$prime = function () {
    return _colgroup$prime;
};
var colgroup$prime1 = /* #__PURE__ */ colgroup$prime();
var colgroup = function () {
    return React_Basic.element(colgroup$prime1);
};
var colgroup1 = /* #__PURE__ */ colgroup();
var colgroup_ = function (children) {
    return colgroup1({
        children: children
    });
};
var _col$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("col"));
var col$prime = function () {
    return _col$prime;
};
var col$prime1 = /* #__PURE__ */ col$prime();
var col = function () {
    return React_Basic.element(col$prime1);
};
var _code$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("code"));
var code$prime = function () {
    return _code$prime;
};
var code$prime1 = /* #__PURE__ */ code$prime();
var code = function () {
    return React_Basic.element(code$prime1);
};
var code1 = /* #__PURE__ */ code();
var code_ = function (children) {
    return code1({
        children: children
    });
};
var _cite$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("cite"));
var cite$prime = function () {
    return _cite$prime;
};
var cite$prime1 = /* #__PURE__ */ cite$prime();
var cite = function () {
    return React_Basic.element(cite$prime1);
};
var cite1 = /* #__PURE__ */ cite();
var cite_ = function (children) {
    return cite1({
        children: children
    });
};
var _caption$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("caption"));
var caption$prime = function () {
    return _caption$prime;
};
var caption$prime1 = /* #__PURE__ */ caption$prime();
var caption = function () {
    return React_Basic.element(caption$prime1);
};
var caption1 = /* #__PURE__ */ caption();
var caption_ = function (children) {
    return caption1({
        children: children
    });
};
var _canvas$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("canvas"));
var canvas$prime = function () {
    return _canvas$prime;
};
var canvas$prime1 = /* #__PURE__ */ canvas$prime();
var canvas = function () {
    return React_Basic.element(canvas$prime1);
};
var canvas1 = /* #__PURE__ */ canvas();
var canvas_ = function (children) {
    return canvas1({
        children: children
    });
};
var _button$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("button"));
var button$prime = function () {
    return _button$prime;
};
var button$prime1 = /* #__PURE__ */ button$prime();
var button = function () {
    return React_Basic.element(button$prime1);
};
var button1 = /* #__PURE__ */ button();
var button_ = function (children) {
    return button1({
        children: children
    });
};
var _br$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("br"));
var br$prime = function () {
    return _br$prime;
};
var br$prime1 = /* #__PURE__ */ br$prime();
var br = function () {
    return React_Basic.element(br$prime1);
};
var _body$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("body"));
var body$prime = function () {
    return _body$prime;
};
var body$prime1 = /* #__PURE__ */ body$prime();
var body = function () {
    return React_Basic.element(body$prime1);
};
var body1 = /* #__PURE__ */ body();
var body_ = function (children) {
    return body1({
        children: children
    });
};
var _blockquote$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("blockquote"));
var blockquote$prime = function () {
    return _blockquote$prime;
};
var blockquote$prime1 = /* #__PURE__ */ blockquote$prime();
var blockquote = function () {
    return React_Basic.element(blockquote$prime1);
};
var blockquote1 = /* #__PURE__ */ blockquote();
var blockquote_ = function (children) {
    return blockquote1({
        children: children
    });
};
var _bdo$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("bdo"));
var bdo$prime = function () {
    return _bdo$prime;
};
var bdo$prime1 = /* #__PURE__ */ bdo$prime();
var bdo = function () {
    return React_Basic.element(bdo$prime1);
};
var bdo1 = /* #__PURE__ */ bdo();
var bdo_ = function (children) {
    return bdo1({
        children: children
    });
};
var _bdi$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("bdi"));
var bdi$prime = function () {
    return _bdi$prime;
};
var bdi$prime1 = /* #__PURE__ */ bdi$prime();
var bdi = function () {
    return React_Basic.element(bdi$prime1);
};
var bdi1 = /* #__PURE__ */ bdi();
var bdi_ = function (children) {
    return bdi1({
        children: children
    });
};
var _base$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("base"));
var base$prime = function () {
    return _base$prime;
};
var base$prime1 = /* #__PURE__ */ base$prime();
var base = function () {
    return React_Basic.element(base$prime1);
};
var _b$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("b"));
var b$prime = function () {
    return _b$prime;
};
var b$prime1 = /* #__PURE__ */ b$prime();
var b = function () {
    return React_Basic.element(b$prime1);
};
var b1 = /* #__PURE__ */ b();
var b_ = function (children) {
    return b1({
        children: children
    });
};
var _audio$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("audio"));
var audio$prime = function () {
    return _audio$prime;
};
var audio$prime1 = /* #__PURE__ */ audio$prime();
var audio = function () {
    return React_Basic.element(audio$prime1);
};
var audio1 = /* #__PURE__ */ audio();
var audio_ = function (children) {
    return audio1({
        children: children
    });
};
var _aside$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("aside"));
var aside$prime = function () {
    return _aside$prime;
};
var aside$prime1 = /* #__PURE__ */ aside$prime();
var aside = function () {
    return React_Basic.element(aside$prime1);
};
var aside1 = /* #__PURE__ */ aside();
var aside_ = function (children) {
    return aside1({
        children: children
    });
};
var _article$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("article"));
var article$prime = function () {
    return _article$prime;
};
var article$prime1 = /* #__PURE__ */ article$prime();
var article = function () {
    return React_Basic.element(article$prime1);
};
var article1 = /* #__PURE__ */ article();
var article_ = function (children) {
    return article1({
        children: children
    });
};
var _area$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("area"));
var area$prime = function () {
    return _area$prime;
};
var area$prime1 = /* #__PURE__ */ area$prime();
var area = function () {
    return React_Basic.element(area$prime1);
};
var _address$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("address"));
var address$prime = function () {
    return _address$prime;
};
var address$prime1 = /* #__PURE__ */ address$prime();
var address = function () {
    return React_Basic.element(address$prime1);
};
var address1 = /* #__PURE__ */ address();
var address_ = function (children) {
    return address1({
        children: children
    });
};
var _abbr$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("abbr"));
var abbr$prime = function () {
    return _abbr$prime;
};
var abbr$prime1 = /* #__PURE__ */ abbr$prime();
var abbr = function () {
    return React_Basic.element(abbr$prime1);
};
var abbr1 = /* #__PURE__ */ abbr();
var abbr_ = function (children) {
    return abbr1({
        children: children
    });
};
var _a$prime = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(/* #__PURE__ */ React_Basic_DOM_Internal.unsafeCreateDOMComponent("a"));
var a$prime = function () {
    return _a$prime;
};
var a$prime1 = /* #__PURE__ */ a$prime();
var a = function () {
    return React_Basic.element(a$prime1);
};
var a1 = /* #__PURE__ */ a();
var a_ = function (children) {
    return a1({
        children: children
    });
};
export {
    a,
    a_,
    a$prime,
    _a$prime,
    abbr,
    abbr_,
    abbr$prime,
    _abbr$prime,
    address,
    address_,
    address$prime,
    _address$prime,
    area,
    area$prime,
    _area$prime,
    article,
    article_,
    article$prime,
    _article$prime,
    aside,
    aside_,
    aside$prime,
    _aside$prime,
    audio,
    audio_,
    audio$prime,
    _audio$prime,
    b,
    b_,
    b$prime,
    _b$prime,
    base,
    base$prime,
    _base$prime,
    bdi,
    bdi_,
    bdi$prime,
    _bdi$prime,
    bdo,
    bdo_,
    bdo$prime,
    _bdo$prime,
    blockquote,
    blockquote_,
    blockquote$prime,
    _blockquote$prime,
    body,
    body_,
    body$prime,
    _body$prime,
    br,
    br$prime,
    _br$prime,
    button,
    button_,
    button$prime,
    _button$prime,
    canvas,
    canvas_,
    canvas$prime,
    _canvas$prime,
    caption,
    caption_,
    caption$prime,
    _caption$prime,
    cite,
    cite_,
    cite$prime,
    _cite$prime,
    code,
    code_,
    code$prime,
    _code$prime,
    col,
    col$prime,
    _col$prime,
    colgroup,
    colgroup_,
    colgroup$prime,
    _colgroup$prime,
    data$prime,
    data_,
    data$prime$prime,
    _data$prime,
    datalist,
    datalist_,
    datalist$prime,
    _datalist$prime,
    dd,
    dd_,
    dd$prime,
    _dd$prime,
    del,
    del_,
    del$prime,
    _del$prime,
    details,
    details_,
    details$prime,
    _details$prime,
    dfn,
    dfn_,
    dfn$prime,
    _dfn$prime,
    dialog,
    dialog_,
    dialog$prime,
    _dialog$prime,
    div,
    div_,
    div$prime,
    _div$prime,
    dl,
    dl_,
    dl$prime,
    _dl$prime,
    dt,
    dt_,
    dt$prime,
    _dt$prime,
    em,
    em_,
    em$prime,
    _em$prime,
    embed,
    embed$prime,
    _embed$prime,
    fieldset,
    fieldset_,
    fieldset$prime,
    _fieldset$prime,
    figcaption,
    figcaption_,
    figcaption$prime,
    _figcaption$prime,
    figure,
    figure_,
    figure$prime,
    _figure$prime,
    footer,
    footer_,
    footer$prime,
    _footer$prime,
    form,
    form_,
    form$prime,
    _form$prime,
    h1,
    h1_,
    h1$prime,
    _h1$prime,
    h2,
    h2_,
    h2$prime,
    _h2$prime,
    h3,
    h3_,
    h3$prime,
    _h3$prime,
    h4,
    h4_,
    h4$prime,
    _h4$prime,
    h5,
    h5_,
    h5$prime,
    _h5$prime,
    h6,
    h6_,
    h6$prime,
    _h6$prime,
    head,
    head_,
    head$prime,
    _head$prime,
    header,
    header_,
    header$prime,
    _header$prime,
    hgroup,
    hgroup_,
    hgroup$prime,
    _hgroup$prime,
    hr,
    hr$prime,
    _hr$prime,
    html,
    html_,
    html$prime,
    _html$prime,
    i,
    i_,
    i$prime,
    _i$prime,
    iframe,
    iframe_,
    iframe$prime,
    _iframe$prime,
    img,
    img$prime,
    _img$prime,
    input,
    input$prime,
    _input$prime,
    ins,
    ins_,
    ins$prime,
    _ins$prime,
    kbd,
    kbd_,
    kbd$prime,
    _kbd$prime,
    keygen,
    keygen_,
    keygen$prime,
    _keygen$prime,
    label,
    label_,
    label$prime,
    _label$prime,
    legend,
    legend_,
    legend$prime,
    _legend$prime,
    li,
    li_,
    li$prime,
    _li$prime,
    link,
    link$prime,
    _link$prime,
    main,
    main_,
    main$prime,
    _main$prime,
    map,
    map_,
    map$prime,
    _map$prime,
    mark,
    mark_,
    mark$prime,
    _mark$prime,
    math,
    math_,
    math$prime,
    _math$prime,
    menu,
    menu_,
    menu$prime,
    _menu$prime,
    menuitem,
    menuitem_,
    menuitem$prime,
    _menuitem$prime,
    meta,
    meta$prime,
    _meta$prime,
    meter,
    meter_,
    meter$prime,
    _meter$prime,
    nav,
    nav_,
    nav$prime,
    _nav$prime,
    noscript,
    noscript_,
    noscript$prime,
    _noscript$prime,
    object,
    object_,
    object$prime,
    _object$prime,
    ol,
    ol_,
    ol$prime,
    _ol$prime,
    optgroup,
    optgroup_,
    optgroup$prime,
    _optgroup$prime,
    option,
    option_,
    option$prime,
    _option$prime,
    output,
    output_,
    output$prime,
    _output$prime,
    p,
    p_,
    p$prime,
    _p$prime,
    param,
    param$prime,
    _param$prime,
    picture,
    picture_,
    picture$prime,
    _picture$prime,
    pre,
    pre_,
    pre$prime,
    _pre$prime,
    progress,
    progress_,
    progress$prime,
    _progress$prime,
    q,
    q_,
    q$prime,
    _q$prime,
    rb,
    rb_,
    rb$prime,
    _rb$prime,
    rp,
    rp_,
    rp$prime,
    _rp$prime,
    rt,
    rt_,
    rt$prime,
    _rt$prime,
    rtc,
    rtc_,
    rtc$prime,
    _rtc$prime,
    ruby,
    ruby_,
    ruby$prime,
    _ruby$prime,
    s,
    s_,
    s$prime,
    _s$prime,
    samp,
    samp_,
    samp$prime,
    _samp$prime,
    script,
    script_,
    script$prime,
    _script$prime,
    section,
    section_,
    section$prime,
    _section$prime,
    select,
    select_,
    select$prime,
    _select$prime,
    slot,
    slot_,
    slot$prime,
    _slot$prime,
    small,
    small_,
    small$prime,
    _small$prime,
    source,
    source$prime,
    _source$prime,
    span,
    span_,
    span$prime,
    _span$prime,
    strong,
    strong_,
    strong$prime,
    _strong$prime,
    style,
    style_,
    style$prime,
    _style$prime,
    sub,
    sub_,
    sub$prime,
    _sub$prime,
    summary,
    summary_,
    summary$prime,
    _summary$prime,
    sup,
    sup_,
    sup$prime,
    _sup$prime,
    table,
    table_,
    table$prime,
    _table$prime,
    tbody,
    tbody_,
    tbody$prime,
    _tbody$prime,
    td,
    td_,
    td$prime,
    _td$prime,
    template,
    template_,
    template$prime,
    _template$prime,
    textarea,
    textarea_,
    textarea$prime,
    _textarea$prime,
    tfoot,
    tfoot_,
    tfoot$prime,
    _tfoot$prime,
    th,
    th_,
    th$prime,
    _th$prime,
    thead,
    thead_,
    thead$prime,
    _thead$prime,
    time,
    time_,
    time$prime,
    _time$prime,
    title,
    title_,
    title$prime,
    _title$prime,
    tr,
    tr_,
    tr$prime,
    _tr$prime,
    track,
    track$prime,
    _track$prime,
    u,
    u_,
    u$prime,
    _u$prime,
    ul,
    ul_,
    ul$prime,
    _ul$prime,
    $$var as var,
    var_,
    var$prime,
    _var$prime,
    video,
    video_,
    video$prime,
    _video$prime,
    wbr,
    wbr$prime,
    _wbr$prime
};
