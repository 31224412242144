// Generated by purs version 0.15.12
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as React_Basic_Hooks from "../React.Basic.Hooks/index.js";
import * as React_Basic_Hooks_Internal from "../React.Basic.Hooks.Internal/index.js";
import * as Type_Equality from "../Type.Equality/index.js";
import * as Web_HTML from "../Web.HTML/index.js";
import * as Web_HTML_Window from "../Web.HTML.Window/index.js";
import * as Web_Storage_Storage from "../Web.Storage.Storage/index.js";
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Effect.bindEffect);
var coerceHook = /* #__PURE__ */ React_Basic_Hooks_Internal.coerceHook();
var bind = /* #__PURE__ */ React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender);
var discard = /* #__PURE__ */ React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender);
var useEffect = /* #__PURE__ */ React_Basic_Hooks.useEffect(Data_Eq.eqUnit);
var map = /* #__PURE__ */ Data_Functor.map(Effect.functorEffect);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl));
var UseLocalStorage = function (x) {
    return x;
};
var newtypeUseLocalStorage_ = {
    Coercible0: function () {
        return undefined;
    }
};
var useLocalStorage = function (key) {
    var readLocalStorage = bindFlipped(Web_Storage_Storage.getItem(key))(bindFlipped(Web_HTML_Window.localStorage)(Web_HTML.window));
    return coerceHook(bind(React_Basic_Hooks["useState$prime"](Data_Maybe.Nothing.value))(function (v) {
        return discard(useEffect(Data_Unit.unit)(map(pure)(bindFlipped(v.value1)(readLocalStorage))))(function () {
            return pure1(v.value0);
        });
    }));
};
export {
    useLocalStorage,
    UseLocalStorage,
    newtypeUseLocalStorage_
};
