{
  "homepage": {
    "header": {
      "logo-title": "",
      "navigation": {
        "services": "Services",
        "about": "About",
        "contact": "Contact me"
      }
    },
    "content": {
      "catchphrase": {
        "one": "CTO for 8 years",
        "two": "Developer for 15",
        "three": "Joker forever"
      },
      "description": {
        "who": "Who am I?",
        "work-with-me": "Work with me"
      },
      "about": {
        "introduction": "Developer, CTO, and now freelance. I'm fullstack but I'm happier on the server side of things.",
        "more": "More details"
      },
      "contact": {
        "title": "Work with me?",
        "introduction": "Having a project, thinking my experience might be of use for you? Needing some help for a small task, or a long term partnership? Let's talk!",
        "email": "Write to me",
        "book": "Book a meeting"
      }
    },
    "footer": {
      "legals": "Legals",
      "why-french-only": "Legals are only available in French. Sorry.",
      "hosting": "Hosting",
      "phone": "Phone:",
      "responsability": "Content published under responsability of Frédéric Menou."
    }
  }
}
