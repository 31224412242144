// Generated by purs version 0.15.12
import * as $foreign from "./foreign.js";
import * as React_Basic from "../React.Basic/index.js";
import * as Unsafe_Coerce from "../Unsafe.Coerce/index.js";
var unsafeCreateDOMComponent = /* #__PURE__ */ $foreign.unsafeCreateDOMComponent_(React_Basic.element);
var semigroupCSS = {
    append: function (a) {
        return function (b) {
            return $foreign.mergeStyles([ b, a ]);
        };
    }
};
var css = Unsafe_Coerce.unsafeCoerce;
var monoidCSS = {
    mempty: /* #__PURE__ */ css({}),
    Semigroup0: function () {
        return semigroupCSS;
    }
};
export {
    mergeStyles,
    unsafeCreateDOMComponent_
} from "./foreign.js";
export {
    css,
    unsafeCreateDOMComponent,
    semigroupCSS,
    monoidCSS
};
