// Generated by purs version 0.15.12
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Homepage_Services from "../Homepage.Services/index.js";
import * as Hooks_UseTranslation from "../Hooks.UseTranslation/index.js";
import * as React_Basic from "../React.Basic/index.js";
import * as React_Basic_DOM from "../React.Basic.DOM/index.js";
import * as React_Basic_DOM_Generated from "../React.Basic.DOM.Generated/index.js";
import * as React_Basic_Hooks from "../React.Basic.Hooks/index.js";
import * as React_Basic_Hooks_Internal from "../React.Basic.Hooks.Internal/index.js";
import * as Routes from "../Routes/index.js";
import * as Type_Equality from "../Type.Equality/index.js";
import * as Utils from "../Utils/index.js";
import * as Web_HTML from "../Web.HTML/index.js";
import * as Web_HTML_Window from "../Web.HTML.Window/index.js";
var a = /* #__PURE__ */ React_Basic_DOM_Generated.a();
var div = /* #__PURE__ */ React_Basic_DOM_Generated.div();
var fold = /* #__PURE__ */ Data_Foldable.fold(Data_Foldable.foldableArray)(React_Basic.monoidJSX);
var bind = /* #__PURE__ */ React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender);
var discard = /* #__PURE__ */ React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender);
var useEffect = /* #__PURE__ */ React_Basic_Hooks.useEffect(Routes.eqRoute);
var map = /* #__PURE__ */ Data_Functor.map(Effect.functorEffect);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Effect.bindEffect);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl));
var pure2 = /* #__PURE__ */ Control_Applicative.pure(Control_Applicative.applicativeArray);
var simpleLink = function (href) {
    return function (text) {
        return a({
            href: href,
            children: [ React_Basic_DOM.text(text) ]
        });
    };
};
var outsideLink = function (href) {
    return function (text) {
        return a({
            href: href,
            target: "_blank",
            children: [ React_Basic_DOM.text(text) ]
        });
    };
};
var moreActions = function (children) {
    return div({
        className: "more-actions",
        children: children
    });
};
var innerLink = function (newRoute) {
    return function (text) {
        return Routes.link_(newRoute)([ React_Basic_DOM.text(text) ]);
    };
};
var ctas = function (children) {
    return div({
        className: "ctas",
        children: children
    });
};
var cta = function (key) {
    return function (children) {
        return React_Basic.keyed(key)(div({
            className: "cta",
            children: children
        }));
    };
};
var landingPage = function (v) {
    return div({
        className: "landing",
        children: [ React_Basic_DOM_Generated.h1_(Utils.newlines([ v.t("catchphrase.one"), v.t("catchphrase.two"), v.t("catchphrase.three") ])), ctas([ cta("who")([ innerLink(Routes.About.value)(v.t("description.who")) ]), cta("work-with-me")([ innerLink(Routes.WorkWithMe.value)(v.t("description.work-with-me")) ]) ]) ]
    });
};
var contact = function (v) {
    return fold([ React_Basic_DOM_Generated.h1_([ React_Basic_DOM.text(v.t("contact.title")) ]), React_Basic_DOM_Generated.p_([ React_Basic_DOM.text(v.t("contact.introduction")) ]), moreActions([ cta("email")([ simpleLink("mailto:contact@funkythunks.dev")(v.t("contact.email")) ]), cta("book")([ outsideLink("https://calendly.com/frederic-menou/contact")(v.t("contact.book")) ]) ]) ]);
};
var about = function (v) {
    return fold([ React_Basic_DOM_Generated.p_([ React_Basic_DOM.text(v.t("about.introduction")) ]), moreActions([ cta("about")([ outsideLink("https://frederic.menou.me")(v.t("about.more")) ]) ]) ]);
};
var mkComponent = function __do() {
    var services = Homepage_Services.mkComponent();
    return React_Basic_Hooks.component("Homepage.Content")(function (v) {
        return bind(Routes.useRoute)(function (currentRoute) {
            return bind(Hooks_UseTranslation.usePrefixedTranslation("homepage.content"))(function (features) {
                return discard(useEffect(currentRoute)(map(pure)(bindFlipped(Web_HTML_Window.scroll(0)(0))(Web_HTML.window))))(function () {
                    return pure1(React_Basic_DOM_Generated.main_(pure2((function () {
                        if (currentRoute instanceof Routes.LandingPage) {
                            return landingPage;
                        };
                        if (currentRoute instanceof Routes.Services) {
                            return Data_Function["const"](services(Data_Unit.unit));
                        };
                        if (currentRoute instanceof Routes.WorkWithMe) {
                            return contact;
                        };
                        if (currentRoute instanceof Routes.Contact) {
                            return contact;
                        };
                        if (currentRoute instanceof Routes.About) {
                            return about;
                        };
                        throw new Error("Failed pattern match at Homepage.Content (line 27, column 9 - line 32, column 31): " + [ currentRoute.constructor.name ]);
                    })()(features))));
                });
            });
        });
    })();
};
export {
    mkComponent
};
