// Generated by purs version 0.15.12
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Except from "../Control.Monad.Except/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Identity from "../Data.Identity/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Exception from "../Effect.Exception/index.js";
import * as Foreign from "../Foreign/index.js";
import * as $$Promise from "../Promise/index.js";
import * as Promise_Rejection from "../Promise.Rejection/index.js";
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Effect.functorEffect);
var mempty = /* #__PURE__ */ Data_Monoid.mempty(Effect_Aff.monoidCanceler);
var thenOrCatch = /* #__PURE__ */ $$Promise.thenOrCatch();
var map = /* #__PURE__ */ Data_Functor.map(Effect.functorEffect);
var resolve = /* #__PURE__ */ $$Promise.resolve();
var $$new = /* #__PURE__ */ $$Promise["new"]();
var alt = /* #__PURE__ */ Control_Alt.alt(Data_Maybe.altMaybe);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var readString = /* #__PURE__ */ Foreign.readString(Data_Identity.monadIdentity);
var bind = /* #__PURE__ */ Control_Bind.bind(Effect_Aff.bindAff);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
var toAff$prime = function (customCoerce) {
    return function (p) {
        return Effect_Aff.makeAff(function (cb) {
            return voidRight(mempty)(thenOrCatch(function (a) {
                return map(resolve)(cb(new Data_Either.Right(a)));
            })(function (e) {
                return map(resolve)(cb(new Data_Either.Left(customCoerce(e))));
            })(p));
        });
    };
};
var fromAff = function () {
    return function (aff) {
        return $$new(function (succ) {
            return function (err) {
                return Effect_Aff.runAff_(Data_Either.either(function ($13) {
                    return err(Promise_Rejection.fromError($13));
                })(succ))(aff);
            };
        });
    };
};
var coerce = function (rej) {
    return Data_Maybe["fromMaybe$prime"](function (v) {
        return Effect_Exception.error("Promise failed, couldn't extract JS Error or String");
    })(alt(Promise_Rejection.toError(rej))(map1(Effect_Exception.error)(Data_Either.hush(Control_Monad_Except.runExcept(readString(Foreign.unsafeToForeign(rej)))))));
};
var toAff = /* #__PURE__ */ toAff$prime(coerce);
var toAffE = function (f) {
    return bind(liftEffect(f))(toAff);
};
export {
    coerce,
    fromAff,
    toAff,
    toAff$prime,
    toAffE
};
