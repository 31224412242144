import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import english from '../../src/i18n/en.json';
import french  from '../../src/i18n/fr.json';

export const setupMatomo = domain => siteId => () => {
  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var url = "https://" + domain + "/";
    _paq.push(['setTrackerUrl', url + 'matomo.php']);
    _paq.push(['setSiteId', siteId]);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = url+'matomo.js';
    s.parentNode.insertBefore(g,s);
  })();
};

export const setupI18n = () => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug: true,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false
      },
      resources: {
        en: { translation: english },
        fr: { translation: french }
      }
    });
  return i18n;
}
