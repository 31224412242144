// Generated by purs version 0.15.12
import * as $foreign from "./foreign.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Maybe_Trans from "../Control.Monad.Maybe.Trans/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as Homepage_1 from "../Homepage/index.js";
import * as React_Basic_DOM_Client from "../React.Basic.DOM.Client/index.js";
import * as Web_DOM_Element from "../Web.DOM.Element/index.js";
import * as Web_DOM_NonElementParentNode from "../Web.DOM.NonElementParentNode/index.js";
import * as Web_HTML from "../Web.HTML/index.js";
import * as Web_HTML_HTMLDocument from "../Web.HTML.HTMLDocument/index.js";
import * as Web_HTML_Window from "../Web.HTML.Window/index.js";
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Effect.bindEffect);
var bindFlipped1 = /* #__PURE__ */ Control_Bind.bindFlipped(Data_Maybe.bindMaybe);
var applySecond = /* #__PURE__ */ Control_Apply.applySecond(Effect.applyEffect);
var Homepage = /* #__PURE__ */ (function () {
    function Homepage() {

    };
    Homepage.value = new Homepage();
    return Homepage;
})();
var pickApplication = function (v) {
    return Homepage_1.mkApplication;
};
var noRoot = /* #__PURE__ */ Effect_Console.log("No root found, aborting.");
var getDocument = /* #__PURE__ */ Data_Functor.map(Effect.functorEffect)(Web_HTML_HTMLDocument.toNonElementParentNode)(/* #__PURE__ */ bindFlipped(Web_HTML_Window.document)(Web_HTML.window));
var lookupEntryPoint = /* #__PURE__ */ bindFlipped(/* #__PURE__ */ Web_DOM_NonElementParentNode.getElementById("entry-point"))(getDocument);
var dispatch = function (application) {
    return function (root) {
        return function __do() {
            var component = pickApplication(application)();
            return React_Basic_DOM_Client.renderRoot(root)(component(Data_Unit.unit))();
        };
    };
};
var decodeApplicationTag = /* #__PURE__ */ (function () {
    return Data_Function["const"](Control_Applicative.pure(Data_Maybe.applicativeMaybe)(Homepage.value));
})();
var inferApplication = function (elt) {
    return function __do() {
        var tag = Web_DOM_Element.getAttribute("data-application")(elt)();
        return bindFlipped1(decodeApplicationTag)(tag);
    };
};
var lookupApplication = /* #__PURE__ */ Control_Bind.bindFlipped(/* #__PURE__ */ Control_Monad_Maybe_Trans.bindMaybeT(Effect.monadEffect))(inferApplication)(lookupEntryPoint);
var lookupApplicationAndEntryPoint = /* #__PURE__ */ (function () {
    return Control_Apply.apply(Control_Monad_Maybe_Trans.applyMaybeT(Effect.monadEffect))(Data_Functor.map(Control_Monad_Maybe_Trans.functorMaybeT(Effect.functorEffect))(Data_Tuple.Tuple.create)(lookupApplication))(lookupEntryPoint);
})();
var boot = function (withRoot) {
    var strap = function (v) {
        return bindFlipped(withRoot(v.value0))(React_Basic_DOM_Client.createRoot(v.value1));
    };
    return bindFlipped(Data_Maybe.maybe(noRoot)(strap))(Control_Monad_Maybe_Trans.runMaybeT(lookupApplicationAndEntryPoint));
};
var main = function __do() {
    $foreign.setupMatomo("kiwi.menou.me")("2")();
    return applySecond($foreign.setupI18n)(boot(dispatch))();
};
export {
    setupMatomo,
    setupI18n
} from "./foreign.js";
export {
    main,
    boot,
    noRoot,
    dispatch,
    pickApplication,
    lookupApplicationAndEntryPoint,
    lookupEntryPoint,
    lookupApplication,
    inferApplication,
    Homepage,
    decodeApplicationTag,
    getDocument
};
