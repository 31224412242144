// Generated by purs version 0.15.12
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as React_Basic_Hooks from "../React.Basic.Hooks/index.js";
import * as React_Basic_Hooks_Internal from "../React.Basic.Hooks.Internal/index.js";
import * as Routing_Duplex from "../Routing.Duplex/index.js";
import * as Routing_PushState from "../Routing.PushState/index.js";
import * as Type_Equality from "../Type.Equality/index.js";
var coerceHook = /* #__PURE__ */ React_Basic_Hooks_Internal.coerceHook();
var bind = /* #__PURE__ */ React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender);
var discard = /* #__PURE__ */ React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender);
var matchesWith = /* #__PURE__ */ Routing_PushState.matchesWith(Data_Foldable.foldableEither);
var pure = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl));
var UseRoute = function (x) {
    return x;
};
var newtypeUseRoute_ = {
    Coercible0: function () {
        return undefined;
    }
};
var useRoute = function (codec) {
    return function (defaultRoute) {
        return function (pushStateInterface) {
            return coerceHook(bind(React_Basic_Hooks["useState$prime"](defaultRoute))(function (v) {
                return discard(React_Basic_Hooks.useEffectOnce(matchesWith(Routing_Duplex.parse(codec))(Data_Function["const"](v.value1))(pushStateInterface)))(function () {
                    return pure(v.value0);
                });
            }));
        };
    };
};
export {
    useRoute,
    UseRoute,
    newtypeUseRoute_
};
