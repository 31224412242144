// Generated by purs version 0.15.12
import * as Control_Apply_Indexed from "../Control.Apply.Indexed/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Function from "../Data.Function/index.js";
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var idiscard = function (dict) {
    return dict.idiscard;
};
var ibind = function (dict) {
    return dict.ibind;
};
var ibindFlipped = function (dictIxBind) {
    return Data_Function.flip(ibind(dictIxBind));
};
var ijoin = function (dictIxBind) {
    var ibind1 = ibind(dictIxBind);
    return function (m) {
        return ibind1(m)(identity);
    };
};
var ixDiscardUnit = {
    idiscard: function (dictIxBind) {
        return ibind(dictIxBind);
    }
};
var composeiKleisliFlipped = function (dictIxBind) {
    var ibindFlipped1 = ibindFlipped(dictIxBind);
    return function (f) {
        return function (g) {
            return function (a) {
                return ibindFlipped1(f)(g(a));
            };
        };
    };
};
var composeiKleisli = function (dictIxBind) {
    var ibind1 = ibind(dictIxBind);
    return function (f) {
        return function (g) {
            return function (a) {
                return ibind1(f(a))(g);
            };
        };
    };
};
export {
    ibind,
    ibindFlipped,
    composeiKleisli,
    composeiKleisliFlipped,
    idiscard,
    ijoin,
    ixDiscardUnit
};
export {
    iapply,
    iapplyFirst,
    iapplySecond,
    imap,
    ivoid,
    ivoidLeft,
    ivoidRight
} from "../Control.Apply.Indexed/index.js";
