// Generated by purs version 0.15.12
import * as $foreign from "./foreign.js";
import * as React_Basic from "../React.Basic/index.js";
var markdown = function (content) {
    return React_Basic.element($foreign.markdownComponent)({
        children: content
    });
};
export {
    markdown
};
