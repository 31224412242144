// Generated by purs version 0.15.12
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Homepage_Content from "../Homepage.Content/index.js";
import * as Homepage_Footer from "../Homepage.Footer/index.js";
import * as Homepage_Header from "../Homepage.Header/index.js";
import * as React_Basic from "../React.Basic/index.js";
import * as React_Basic_Hooks from "../React.Basic.Hooks/index.js";
import * as React_Basic_Hooks_Internal from "../React.Basic.Hooks.Internal/index.js";
import * as Type_Equality from "../Type.Equality/index.js";
var pure = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl));
var fold = /* #__PURE__ */ Data_Foldable.fold(Data_Foldable.foldableArray)(/* #__PURE__ */ Data_Monoid.monoidFn(React_Basic.monoidJSX));
var mkApplication = function __do() {
    var header = Homepage_Header.mkComponent();
    var content = Homepage_Content.mkComponent();
    var footer = Homepage_Footer.mkComponent();
    return React_Basic_Hooks.component("Homepage")((function () {
        var $7 = fold([ header, content, footer ]);
        return function ($8) {
            return pure($7($8));
        };
    })())();
};
export {
    mkApplication
};
