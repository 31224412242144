// Generated by purs version 0.15.12
import * as $foreign from "./foreign.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Nullable from "../Data.Nullable/index.js";
import * as Promise_Aff from "../Promise.Aff/index.js";
import * as React_Basic_Hooks_Internal from "../React.Basic.Hooks.Internal/index.js";
var map = /* #__PURE__ */ Data_Functor.map(React_Basic_Hooks_Internal.functorRender);
var fallback = function (v) {
    if (v === "") {
        return "en";
    };
    return v;
};
var readFeatures = function (v) {
    return {
        t: v.t,
        language: fallback($foreign.languageImpl(v.i18n)),
        changeLanguage: (function () {
            var $9 = $foreign.changeLanguageImpl(v.i18n);
            return function ($10) {
                return Promise_Aff.toAffE($9($10));
            };
        })()
    };
};
var useTranslationFull = function (namespace) {
    return function (prefix) {
        return map(readFeatures)(React_Basic_Hooks_Internal.unsafeHook(function () {
            return $foreign.useTranslationImpl(Data_Nullable.toNullable(namespace), {
                keyPrefix: Data_Nullable.toNullable(prefix)
            });
        }));
    };
};
var useNamespacedPrefixedTranslation = function (ns) {
    return function (prefix) {
        return useTranslationFull(new Data_Maybe.Just(ns))(new Data_Maybe.Just(prefix));
    };
};
var useNamespacedTranslation = function (ns) {
    return useTranslationFull(new Data_Maybe.Just(ns))(Data_Maybe.Nothing.value);
};
var usePrefixedTranslation = function (prefix) {
    return useTranslationFull(Data_Maybe.Nothing.value)(new Data_Maybe.Just(prefix));
};
var useTranslation = /* #__PURE__ */ (function () {
    return useTranslationFull(Data_Maybe.Nothing.value)(Data_Maybe.Nothing.value);
})();
var useLanguage = /* #__PURE__ */ map(function (v) {
    return v.language;
})(useTranslation);
export {
    useTranslation,
    useLanguage,
    useNamespacedTranslation,
    usePrefixedTranslation,
    useNamespacedPrefixedTranslation
};
