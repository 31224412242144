// Generated by purs version 0.15.12
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Hooks_UseLocalStorage from "../Hooks.UseLocalStorage/index.js";
import * as Hooks_UseTranslation from "../Hooks.UseTranslation/index.js";
import * as React_Basic from "../React.Basic/index.js";
import * as React_Basic_DOM from "../React.Basic.DOM/index.js";
import * as React_Basic_DOM_Events from "../React.Basic.DOM.Events/index.js";
import * as React_Basic_DOM_Generated from "../React.Basic.DOM.Generated/index.js";
import * as React_Basic_Events from "../React.Basic.Events/index.js";
import * as React_Basic_Hooks from "../React.Basic.Hooks/index.js";
import * as React_Basic_Hooks_Internal from "../React.Basic.Hooks.Internal/index.js";
import * as Routes from "../Routes/index.js";
import * as Type_Equality from "../Type.Equality/index.js";
var bind = /* #__PURE__ */ React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender);
var guard = /* #__PURE__ */ Data_Monoid.guard(/* #__PURE__ */ Data_Monoid.monoidFn(React_Basic.monoidJSX));
var a = /* #__PURE__ */ React_Basic_DOM_Generated.a();
var mempty = /* #__PURE__ */ Data_Monoid.mempty(/* #__PURE__ */ Data_Monoid.monoidFn(/* #__PURE__ */ Effect.monoidEffect(Data_Monoid.monoidUnit)));
var li = /* #__PURE__ */ React_Basic_DOM_Generated.li();
var guard1 = /* #__PURE__ */ Data_Monoid.guard(Data_Monoid.monoidString);
var eq = /* #__PURE__ */ Data_Eq.eq(Routes.eqRoute);
var img = /* #__PURE__ */ React_Basic_DOM_Generated.img();
var pure = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl));
var guard2 = /* #__PURE__ */ Data_Monoid.guard(React_Basic.monoidJSX);
var eq1 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Maybe.eqMaybe(Data_Eq.eqString));
var div = /* #__PURE__ */ React_Basic_DOM_Generated.div();
var mkComponent = /* #__PURE__ */ React_Basic_Hooks.component("Homepage.Header")(function (v) {
    return bind(Routes.useRoute)(function (here) {
        return bind(Hooks_UseLocalStorage.useLocalStorage("funky.extra"))(function (extra) {
            return bind(Hooks_UseTranslation.usePrefixedTranslation("homepage.header"))(function (v1) {
                var mkLanguageSwitcher = function (code) {
                    return function (text) {
                        return guard(code !== v1.language)(a)({
                            onClick: React_Basic_Events.handler(React_Basic_DOM_Events.preventDefault)(Data_Function["const"](Effect_Aff.runAff_(mempty)(v1.changeLanguage(code)))),
                            href: "?lng=" + code,
                            children: [ React_Basic_DOM.text(text) ]
                        });
                    };
                };
                var mkInnerLink = function (to) {
                    return function (key) {
                        return li({
                            className: guard1(eq(to)(here))("current"),
                            children: [ Routes.link_(to)([ React_Basic_DOM.text(v1.t(key)) ]) ]
                        });
                    };
                };
                var logo = img({
                    id: "logo",
                    src: "./logo-orange.svg",
                    alt: "Funky Thunks",
                    title: v1.t("logo-title")
                });
                return pure(React_Basic_DOM_Generated.header_([ React_Basic_DOM_Generated.div_([ Routes.link_(Routes.LandingPage.value)([ logo ]), React_Basic_DOM_Generated.nav_([ React_Basic_DOM_Generated.ul_([ guard2(eq1(extra)(new Data_Maybe.Just("yes")))(mkInnerLink(Routes.Services.value)("navigation.services")), mkInnerLink(Routes.About.value)("navigation.about"), mkInnerLink(Routes.Contact.value)("navigation.contact") ]) ]), div({
                    className: "language-switcher",
                    children: [ mkLanguageSwitcher("en")("English"), mkLanguageSwitcher("fr")("Fran\xe7ais") ]
                }) ]) ]));
            });
        });
    });
});
export {
    mkComponent
};
