// Generated by purs version 0.15.12
import * as $foreign from "./foreign.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Unsafe from "../Effect.Unsafe/index.js";
import * as Foreign from "../Foreign/index.js";
import * as Hooks_UseRoute from "../Hooks.UseRoute/index.js";
import * as React_Basic_DOM_Events from "../React.Basic.DOM.Events/index.js";
import * as React_Basic_DOM_Generated from "../React.Basic.DOM.Generated/index.js";
import * as React_Basic_Events from "../React.Basic.Events/index.js";
import * as Routing_Duplex from "../Routing.Duplex/index.js";
import * as Routing_Duplex_Generic from "../Routing.Duplex.Generic/index.js";
import * as Routing_Duplex_Generic_Syntax from "../Routing.Duplex.Generic.Syntax/index.js";
import * as Routing_PushState from "../Routing.PushState/index.js";
var genericShowConstructor = /* #__PURE__ */ Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments);
var LandingPageIsSymbol = {
    reflectSymbol: function () {
        return "LandingPage";
    }
};
var ServicesIsSymbol = {
    reflectSymbol: function () {
        return "Services";
    }
};
var WorkWithMeIsSymbol = {
    reflectSymbol: function () {
        return "WorkWithMe";
    }
};
var ContactIsSymbol = {
    reflectSymbol: function () {
        return "Contact";
    }
};
var AboutIsSymbol = {
    reflectSymbol: function () {
        return "About";
    }
};
var append = /* #__PURE__ */ Data_Semigroup.append(/* #__PURE__ */ Effect.semigroupEffect(Data_Semigroup.semigroupUnit));
var gsep = /* #__PURE__ */ Routing_Duplex_Generic_Syntax.gsep(/* #__PURE__ */ Routing_Duplex_Generic_Syntax.gsepStringRoute(Routing_Duplex_Generic.gRouteNoArguments));
var a = /* #__PURE__ */ React_Basic_DOM_Generated.a();
var LandingPage = /* #__PURE__ */ (function () {
    function LandingPage() {

    };
    LandingPage.value = new LandingPage();
    return LandingPage;
})();
var Services = /* #__PURE__ */ (function () {
    function Services() {

    };
    Services.value = new Services();
    return Services;
})();
var WorkWithMe = /* #__PURE__ */ (function () {
    function WorkWithMe() {

    };
    WorkWithMe.value = new WorkWithMe();
    return WorkWithMe;
})();
var Contact = /* #__PURE__ */ (function () {
    function Contact() {

    };
    Contact.value = new Contact();
    return Contact;
})();
var About = /* #__PURE__ */ (function () {
    function About() {

    };
    About.value = new About();
    return About;
})();
var genericRoute_ = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return LandingPage.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return Services.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return WorkWithMe.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return Contact.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr))) {
            return About.value;
        };
        throw new Error("Failed pattern match at Routes (line 44, column 1 - line 44, column 32): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof LandingPage) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof Services) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof WorkWithMe) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof Contact) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
        };
        if (x instanceof About) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value))));
        };
        throw new Error("Failed pattern match at Routes (line 44, column 1 - line 44, column 32): " + [ x.constructor.name ]);
    }
};
var showRoute = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericRoute_)(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor(LandingPageIsSymbol))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor(ServicesIsSymbol))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor(WorkWithMeIsSymbol))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor(ContactIsSymbol))(/* #__PURE__ */ genericShowConstructor(AboutIsSymbol))))))
};
var eqRoute = {
    eq: function (x) {
        return function (y) {
            if (x instanceof LandingPage && y instanceof LandingPage) {
                return true;
            };
            if (x instanceof Services && y instanceof Services) {
                return true;
            };
            if (x instanceof WorkWithMe && y instanceof WorkWithMe) {
                return true;
            };
            if (x instanceof Contact && y instanceof Contact) {
                return true;
            };
            if (x instanceof About && y instanceof About) {
                return true;
            };
            return false;
        };
    }
};
var trackPageChange = function (path) {
    return append($foreign.setCustomUrl(path))($foreign.trackPageView);
};
var route = /* #__PURE__ */ Routing_Duplex.root(/* #__PURE__ */ Routing_Duplex_Generic.sum(genericRoute_)(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor(LandingPageIsSymbol)()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor(ServicesIsSymbol)()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor(WorkWithMeIsSymbol)()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor(ContactIsSymbol)()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor(AboutIsSymbol)()(Routing_Duplex_Generic.gRouteNoArguments))))))({
    LandingPage: Routing_Duplex_Generic.noArgs,
    Services: /* #__PURE__ */ gsep("services")(Routing_Duplex_Generic.noArgs),
    WorkWithMe: /* #__PURE__ */ gsep("work-with-me")(Routing_Duplex_Generic.noArgs),
    Contact: /* #__PURE__ */ gsep("contact")(Routing_Duplex_Generic.noArgs),
    About: /* #__PURE__ */ gsep("about")(Routing_Duplex_Generic.noArgs)
}));
var pushStateInterface = /* #__PURE__ */ Effect_Unsafe.unsafePerformEffect(Routing_PushState.makeInterface);
var link_ = function (newRoute) {
    return function (children) {
        var state = Foreign.unsafeToForeign(0);
        var href = Routing_Duplex.print(route)(newRoute);
        var onClick = React_Basic_Events.handler(React_Basic_DOM_Events.preventDefault)(Data_Function["const"](append(pushStateInterface.pushState(state)(href))(trackPageChange(href))));
        return a({
            href: href,
            onClick: onClick,
            children: children
        });
    };
};
var defaultRoute = /* #__PURE__ */ (function () {
    return LandingPage.value;
})();
var useRoute = /* #__PURE__ */ Hooks_UseRoute.useRoute(route)(defaultRoute)(pushStateInterface);
export {
    LandingPage,
    Services,
    WorkWithMe,
    Contact,
    About,
    useRoute,
    link_,
    genericRoute_,
    eqRoute,
    showRoute
};
